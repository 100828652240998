a.btn.btn-sm.btn-primary.bsm-1.vstg,
a.btn.btn-sm.btn-primary.bsm-1 {
  font-size: 13px;
}

input.input-color.color_input {
  width: 100%;
}


.card {
  margin-left: -5px !important;
}

.rolestly .easy-tree li::before {
  border-left: 2px solid #d7d7d7;
  height: 100% !important;
  top: 0 !important;
  width: 1px !important;
}

.rolestly .easy-tree .One1::before {
  display: none !important;
}

.easy-tree li {
  list-style-type: none !important;
  margin: 0;
  padding: 10px 5px 0 5px !important;
  position: relative !important;
}

.easy-tree ul li::before {
  border-left: 2px solid #d7d7d7 !important;
  bottom: 50px !important;
  height: 100% !important;
  top: 0 !important;
  width: 1px !important;
}

.easy-tree li::after {
  border-top: 2px solid #d7d7d7 !important;
  height: 20px !important;
  top: 30px !important;
  width: 35px !important;
}

.easy-tree li::after {
  content: '' !important;
  left: -30px !important;
  position: absolute !important;
  right: auto !important;
}

.easy-tree li::before,
.easy-tree li::after {
  content: '' !important;
  left: -30px !important;
  position: absolute !important;
  right: auto !important;
}

.easy-tree li:last-child::before {
  height: 30px !important;
}

.easy-tree li.parent_li>span {
  cursor: pointer;
}

.easy-tree li>span {
  border: 2px solid #d7d7d7;
  display: inline-block;
  padding: 5px;
  text-decoration: none;
  background: #ececec;

}

.easy-tree li>span>a {
  color: #000;
  text-decoration: none;
}

.rolestly .easy-tree li.parent_li.default>span {
  background: #e43912;
  border-color: #a1331a;
}

.easy-tree li.parent_li.default>span a {
  color: #fff;
}

.text-right12 {
  display: flex;
  justify-content: right;
  text-align: right !important;
}

.btn12:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.btn12.btn-default,
.dataTables_wrapper .dataTables_paginate .btn-default.paginate_button {
  color: #4d5052;
  background-color: #fff;
  border-color: #e8e9e9;
  padding: 6px 18px;
}

.btn12,
.dataTables_wrapper .dataTables_paginate .paginate_button {
  font-size: 14px;
}

.btn12-group-sm>.btn12,
.btn12-sm {
  padding: 0.25rem 0.5rem;
  font-size: .875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn12 {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}






.iconRight i {
  color: #000 !important;
  margin: 2px;
  cursor: pointer !important;
}

.ant-popover-arrow {
  background-color: transparent !important;
}

.ant-popover-inner {
  background-color: transparent !important;
}

.ant-popover-inner {
  background-color: #ffffff;
  background-clip: padding-box;
  border-radius: 8px;
  box-shadow: none !important;
  padding: 5px !important;
}

.ant-space-item {
  height: auto !important;
}

.antButtton {
  width: 6px !important;
  background-color: transparent !important;
  height: auto !important;
  border: none !important;
  outline: none !important;
}

.ant-popover {
  z-index: 0 !important;
}

.ant-modal-footer {
  display: none !important;
}

.cancle2 {
  margin-right: 10px !important;
  margin-top: 4px !important;
}

.input12 {
  width: 100%;
  border-radius: 5px;
  border: 1px solid #d5d5d5;
  height: 38px;
  padding-left: 5px;
}

.dropdown112 {
  width: 100% !important;
}
.span_rem{
  position: relative !important;
}


.parent_li .iconRight.active {
  display: block;
}
.color_box{
  height: 15px !important;
  width: 20px !important;

  margin-left: 8px !important;
  padding: 8px;
  border-radius: 10px;
  padding: 10px;
}
.new_event_color .ant-select-selector{
margin-top: 4px;
  height: 38px !important;
}
.event_color_edit .ant-select-selector{
  height: 38px !important;
}
.new_event_color,.event_color_edit .ant-select-selection-item{
 margin-top: 2px;
}
.color_box_view{
 width: 10px;
 height: 10px;
  border-radius: 5px;
}
.tagss .ant-select-selector{
  overflow-y: scroll !important;
}
.action_meeting_card_avatar {
    width: 35px !important;
    height: 35px !important;
}
